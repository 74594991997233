@import "../../../colorvariable.scss";
@import "../../../mixin.scss";

.progressBar {
    &-bg {
        width: 100%;
        background-color: $text-gray-100;
        height: 8px;
        border-radius: 4px;
        position: relative;
    }

    &-content {
        transition: ease 100ms;
        background-color: $btn-primary-default;
        height: 8px;
        border-radius: 4px;
    }

    &-right {
        display: flex;
        justify-content: flex-end;
    }

    &-label {
        min-width: 5rem;
        text-align: right;
    }
}