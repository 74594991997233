@import "../../../colorvariable.scss";

.three-dots-cont {
	position: relative;

	.three-dots-menu {
		cursor: pointer;
		transition: all 0.3s;

		&:active > svg {
			opacity: 0.6;
			transition: all 0.3s;
		}

		&:hover {
			border-radius: 100%;
			background-color: $hover-color;
		}
	}

	.menu-cont {
		position: absolute;
		top: 0;
		right: 3rem;
		min-width: 100%;
		// width: max-content;
		z-index: 100;
		visibility: hidden;
		opacity: 0;
		transition: all 0.3s;

		&.visible {
			visibility: visible;
			opacity: 1;
			transition: all 0.3s;
		}

		.menu {
			border: 1px solid $border-input;
			border-bottom: 0.05rem solid $border-bottom-dropdown;
			border-radius: 0.8rem;
			padding: 1rem 0;
			background-color: $bg-White;
		}

		.menu-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 2rem;
			padding: 0 1.6rem;
			cursor: pointer;
			text-wrap: balance;
			transition: all 0.3s;

			p {
				padding: 0.8rem 0;
				text-wrap: nowrap;
			}

			&:hover {
				background-color: $dropdown-hover-bg;
				transition: all 0.3s;

				p {
					font-weight: 600;
				}
			}
		}
	}
}
