@import "../../../colorvariable.scss";

.date-picker-dropdown {
	display: flex;
	flex-direction: column;

	label {
		font-weight: 500;
		font-size: 1.4rem;
		transition: all 0.1s ease-in;
		color: $btn-text-gray-default;

		&.isRequired::after {
			content: "*";
			color: $btn-error-default;
		}
	}

	.dty-selector {
		display: flex;
		gap: 1rem;
	}
}
