@import "../../colorvariable.scss";

.history-status {
	h6.xs {
		font-size: 1.6rem;
		font-weight: 600;
	}
	.timeline {
		margin-top: 3.2rem;

		&-item {
			display: flex;
			flex-direction: row;
			gap: 1.6rem;

			> :nth-child(1) {
				display: flex;
				flex-direction: column;
				align-items: center;
				position: relative;
				width: 16px;

				svg {
					position: absolute;
					top: 5px;
					z-index: 2;
					color: $btn-primary-hover;
					font-size: 16px;
					background-color: white;
				}

				.line-to-bottom {
					position: absolute;
					top: 5px;
					z-index: 1;
					width: 2px;
					height: 100%;
					background-color: $btn-primary-hover;
				}
			}

			> :nth-child(2) {
				margin-bottom: 2.4rem;
			}

			&:nth-last-child(1) {
				.line-to-bottom {
					height: 0;
				}

				> :nth-child(2) {
					margin-bottom: 0;
				}
			}
		}

		h6.xs {
			margin-bottom: 1.2rem;
		}
	}
}
