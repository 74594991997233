@import "../../colorvariable.scss";

.withdraw-popup-modal {
	max-width: 65rem;

	.modal-body {
	}

	.body-item {
		padding: 0;

		&.body-item-header,
		&.body-item-footer {
			padding: 3.2rem;
		}
	}

	.withdrawn-popup {
		> div {
			padding: 3.2rem;
		}

		h6.xs {
			font-size: 1.6rem;
			font-weight: 600;
		}

		p.sm {
			color: $btn-text-gray-default;
		}

		.job-title-company {
		}

		.warning-info {
			display: flex;
			padding: 8px;
			margin: 0 3.2rem 0;
			align-items: center;
			gap: 8px;
			align-self: stretch;
			border-radius: 4px;
			background-color: #fff5e6;

			div {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0.6rem;
				width: 2.4rem;
				height: 2.4rem;
				background-color: $icon-warning;
				border-radius: 100rem;

				svg {
					color: #dc6803;
					width: 12px;
					height: 12px;
				}
			}

			p {
				font-weight: 500;
				color: $btn-secondary-hover;
			}
		}

		.bullet-info {
			ul {
				display: flex;
				flex-direction: column;
				gap: 1.2rem;
				margin-bottom: 0;

				li {
					font-size: 8px;
					p {
						font-weight: 500;
					}
				}
			}
		}

		.selection-radio {
			padding-top: 3.2rem;
			border-top: 1px solid $border-input;

			&-title {
				margin-bottom: 2rem;
			}

			&-options {
				display: flex;
				flex-direction: column;
				gap: 1.2rem;
				margin-bottom: 2rem;

				.form-group {
					margin-bottom: 0;

					label {
						color: $btn-text-gray-default;
						font-weight: 400;
					}
				}
			}
		}

		.withdraw-btn-cont {
			border-top: 1px solid $border-input;
		}
	}
}
