@import "../../../../colorvariable.scss";

.modal-info {
	padding: 24px;
	max-width: 60rem;

	.modal-body {
		max-height: 100%;
	}

	.body-item {
		padding: 3.2rem;
		border-top: 1px solid $btn-text-gray-disable;
		border-bottom: 1px solid $btn-text-gray-disable;
		width: 100%;

		&-header,
		&-footer {
			padding-top: 2.4rem;
			padding-bottom: 2.4rem;
			border-top: 0;
			border-bottom: 0;
		}

		&-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.modal-title-label {
				font-size: 2rem;
				margin-bottom: 0;

				& ~ * {
					cursor: pointer;
					color: $btn-text-gray-default;
					transition: all 0.2s;

					&:active {
						opacity: 0.6;
						transition: all 0.2s;
					}
				}
			}
		}
	}

	&.no-header .body-item {
		border-top: 0;
	}

	&.no-footer .body-item {
		border-bottom: 0;
	}

	&__modal-body {
		padding: 0;

		&__button-groups {
			gap: 2.4rem;
			justify-content: space-between;
			width: 100%;

			&-wrapper,
			&-btn {
				width: 100%;
			}
		}
	}
}
