@import "../../../colorvariable.scss";

// J-iconComp means Job-inputComponent
.J-iconComp {
    display: flex;
    justify-content: center;
    // align-items: center;

    &-container {
        border-radius: 50%;
        &.error {
            background-color: #FEF3F2;
            padding: 1.2rem;
            &-bold {
                background-color: $btn-error-hover;
                padding: 1.2rem;
            }
        }

        &.warning {
            background-color: #FFFAEB;
            padding: 1.2rem;
            &-bold {
                background-color: #DC6803;
                padding: 1.2rem;
            }
        }

        &.success {
            background-color: #ECFDF3;
            padding: 1.2rem;
            &-bold {
                background-color: #039855;
                padding: 1.2rem;
            }
        }

        &.reset {
            background-color: #E7EDF7;
            padding: 1.2rem;
            &-bold {
                background-color: $btn-outline-hover;
                padding: 1.2rem;
            }
        }

        &.email {
            background-color: #FFF5E6;
            padding: 1.2rem;
            &-bold {
                background-color: $btn-outline-hover-secondary;
                padding: 1.2rem;
            }
        }

        &.bookmark-outline {
            background-color: $bg-White;
        }

    }

    &-content {
        border-radius: 50%;
        padding: 1.6rem;

        &.error {
            background-color: $icon-error;
            &-bold {
                padding: 1.2rem;
                background-color: $btn-error-default;
            }
        }

        &.warning {
            background-color: $icon-warning;
            &-bold {
                padding: 1.2rem;
                background-color: #F79009;
            }
        }

        &.success {
            background-color: $icon-success;
            &-bold {
                padding: 1.2rem;
                background-color: #12B76A;
            }
        }

        &.reset {
            background-color: $btn-outline-hover;
            &-bold {
                padding: 1.2rem;
                background-color: $btn-outline-hover;
            }
        }

        &.email {
            background-color: $btn-outline-hover-secondary;
            &-bold {
                padding: 1.2rem;
                background-color: $btn-outline-hover-secondary;
            }
        }

        &.bookmark {
            border-radius: .8rem;
            padding: 1.2rem;
            background-color: $btn-primary-default;
            border: 1px solid $btn-primary-default;

            &:hover {
                background-color: $btn-primary-hover;
            }

            &:active {
                box-shadow: 0px 0px 0px 4px $btn-outline-hover, 0px 1px 2px rgba(16, 24, 40, 0.05);
            }

            &.disable,
            &.disable:hover,
            &.disable:active {
                background: $btn-primary-disable;
                border: 1px solid $btn-primary-disable;
                cursor: not-allowed;
            }

            &-outline {
                background-color: $bg-White;
                border: 1px solid $btn-primary-default;
                border-radius: .8rem;
                padding: 1.2rem !important;
                transition: all .1s ease-in;

                &:hover {
                    background: $btn-outline-hover;
                    border: 1px solid $btn-primary-default;
                }
        
                &:active {
                    background: $bg-White;
                    box-shadow: 0px 0px 0px 4px $btn-outline-hover, 0px 1px 2px rgba(16, 24, 40, 0.05);
                }
        
                &-disable,
                &-disable:hover,
                &-disable:active {
                    background: $bg-White;
                    border: 1px solid $btn-primary-disable;
                    color: $btn-outline-text-disable;
                    cursor: not-allowed;
                }
            }
        }
    }

    &-img {
        width: 2.3rem;

        &.error {
            filter: brightness(0) saturate(100%) invert(22%) sepia(52%) saturate(6788%) hue-rotate(354deg) brightness(91%) contrast(86%);
        }

        &.warning {
            filter: brightness(0) saturate(100%) invert(46%) sepia(58%) saturate(4499%) hue-rotate(12deg) brightness(98%) contrast(98%);
        }

        &.success {
            filter: brightness(0) saturate(100%) invert(36%) sepia(36%) saturate(5704%) hue-rotate(138deg) brightness(93%) contrast(98%);
        }

        &.reset {
            filter: brightness(0) saturate(100%) invert(13%) sepia(59%) saturate(6075%) hue-rotate(215deg) brightness(82%) contrast(90%);
        }

        &.email {
            filter: brightness(0) saturate(100%) invert(59%) sepia(97%) saturate(978%) hue-rotate(351deg) brightness(96%) contrast(106%);
        }

        &.bookmark {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(17%) hue-rotate(321deg) brightness(105%) contrast(107%);
        }

        &.bookmark-outline {
            filter: brightness(0) saturate(100%) invert(20%) sepia(97%) saturate(1616%) hue-rotate(206deg) brightness(93%) contrast(102%);
        }

        &.white {
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(17%) hue-rotate(321deg) brightness(105%) contrast(107%);        
        }

    }
}