@import "../../colorvariable.scss";
@import "../../mixin.scss";
@import "../../variables.scss";

.footer-component {
	width: 100%;
	background-color: $white-color;
	display: flex;
	padding: 7.2rem 4.8rem 3rem 4.8rem;
	border-top: 2px solid #f2f4f7;

	@media (max-width: $sm) {
		padding: 5rem 3rem;
	}

	.fc {
		width: 100%;

		h4 {
			font-size: 2rem;
			font-weight: 700;
		}

		.social-icons {
			margin-top: 3rem;
			display: flex;

			@media (max-width: $sm) {
				margin-top: 0;
			}

			.icon {
				height: 5rem;
				cursor: pointer;

				&:not(:last-child()) {
					margin-right: 2rem;
				}
			}
		}

		&-early {
			margin-bottom: 5rem;
			display: flex;
			align-items: flex-start;
			gap: 10rem;
			width: 100%;

			@media (max-width: $sm) {
				margin-bottom: 2rem;
				flex-direction: column;
				gap: 4rem;
				padding-bottom: 2.4rem;
			}

			&-left {
				width: fit-content;
				display: flex;
				flex-direction: column;
				align-items: center;

				@media (max-width: $md) {
					align-items: flex-start;
				}

				.footer-logo {
					@media (max-width: $md) {
						height: 8rem;
					}
				}
			}

			&-mid {
				flex: 1;
				padding-left: 10rem;
				width: 100%;

				@media (max-width: $md) {
					padding-left: 0;
				}

				h4 {
					margin-bottom: 3rem;
				}

				&-list {
					column-count: 2;

					list-style-type: none;
					color: $btn-primary-default;
					font-weight: 600;
					padding-left: 0;
					cursor: pointer;

					@media (min-width: $sm) {
						column-count: 1;
					}

					& li {
						white-space: nowrap;
						margin-bottom: 2rem;

						@media (max-width: $sm) {
							&:nth-child(2),
							&:nth-child(4) {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			&-right {
				width: 30rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				@media (max-width: $sm) {
					width: 100%;
				}

				h4 {
					margin-bottom: 3rem;
				}

				address {
					margin-bottom: 0;
				}

				div {
					display: flex;
					gap: 1rem;
					font-size: 1.6rem;
					font-weight: 600;

					.icon {
						height: 3rem;
						width: auto;
						object-fit: contain;

						position: relative;
						top: 0rem;
					}

					&:not(:last-child) {
						margin-bottom: 1.6rem;
					}
				}
			}
		}

		&-line {
			border-top: 2px solid #f2f4f7;
		}

		&-last {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			padding-top: 3.6rem;

			@media only screen and (max-width: $md) {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 1rem;
				padding-top: 2rem;
			}

			&-text {
				width: 100%;
				text-align: left;
				color: $btn-primary-default;
				font-weight: 400;
				@include text-md;

				@media only screen and (max-width: $sm) {
					padding-top: 1rem;
				}
			}

			// &-icon {
			// 	margin-left: auto;
			// 	display: flex;
			// 	gap: 3rem;
			// 	filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
			// 		saturate(17%) hue-rotate(321deg) brightness(105%) contrast(107%);

			// 	@media only screen and (max-width: $xs) {
			// 		order: 1;
			// 		margin: 0;
			// 	}
			// }
		}
	}
}
