@import "../../colorvariable.scss";
@import "../../mixin.scss";

.my-jobs-component {
	background-color: $bg-input-disable;
	min-height: CALC(100vh - 80px);
	.container {
		padding: 32px 120px;
	}
	.page-heading {
		margin: 0px;
		color: $home-title-color;
		font-size: 1.875em;
		font-weight: 600;
	}
	.data-div {
		background-color: $white-color;
		border-radius: 12px;
		margin-top: 24px;
		padding: 24px;
		border: 1px solid $border-input;

		.header-div {
			display: flex;
			border-bottom: 1px solid $border-input;
			margin-top: 36px;
			height: 36px;
			.header-item {
				width: 140px;
				padding: 8px 10px;
				margin-bottom: -2.5px;
				font-weight: 600;
				color: $btn-text-gray-default;
				cursor: pointer;
				transition: 0.2s;
				span {
					background-color: $btn-secondary-default;
					padding: 0px 8px;
					border-radius: 20px;
					margin-left: 10px;
					color: white;
					font-size: 1.4rem;
				}
				&.selected {
					color: $label-color-blue;
				}
			}
			.border-bottom-blue {
				width: 120px;
				height: 4px;
				border-radius: 2px;
				background-color: $label-color-blue;
				position: absolute;
				display: flex;
				align-self: flex-end;
				margin-bottom: -2px;
				transition: 0.6s;
			}
		}
		.top-bar {
			display: flex;
			width: 100%;
			gap: 3rem;
			align-items: center;
			justify-content: space-between;
			margin-top: 20px;

			.search-bar-div {
				flex: 1;
				border: 1px solid $border-input;
				border-radius: 8px;
				padding: 11px 14px;
				display: flex;
				align-items: center;
				min-width: 250px;

				.search-input {
					border: none;
					outline: none;
					margin-left: 5px;
					width: 100%;
				}
			}
			.date-range-cont {
				flex: 1;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;

				.rmdp-container {
					.rmdp-calendar {
						padding: 1.6rem 2.4rem;

						.rmdp-header > div,
						.rmdp-day-picker {
							gap: 3.8rem;
						}
						.rmdp-arrow-container {
							padding-top: 2px;
						}
						.rmdp-header-values {
							color: $label-colors-lcl-primary;
							font-family: Roboto;
							font-size: 13px;
							font-style: normal;
							font-weight: 500;
							line-height: 18px; /* 138.462% */
							letter-spacing: 0.5px;
						}
						.rmdp-week-day {
							color: $label-colors-lcl-secondary;
						}
						.rmdp-day {
							color: $label-colors-lcl-primary;

							span {
								font-family: Roboto, "Noto Sans", sans-serif;
								font-size: 13px;
								font-style: normal;
								font-weight: 400;
								line-height: 18px; /* 138.462% */
								letter-spacing: 0.5px;
							}
							&.rmdp-range {
								background-color: $dropdown-hover-bg;
								color: $text-black;
								box-shadow: none;

								&-hover {
									background-color: $dropdown-hover-bg;
								}
							}
							&.rmdp-range.end,
							&.rmdp-range.start {
								background-color: $btn-primary-default;
								color: $white-color;
							}
							&.rmdp-today {
								span {
									background-color: $btn-secondary-default;
								}
							}
							&.rmdp-disabled {
								color: $border-input;
							}
							&:not(.rmdp-disabled, .rmdp-day-hidden, .rmdp-selected) span:hover {
								background-color: $btn-primary-default;
								color: #fff;
							}
						}
					}

					.date-bottom-cont {
						padding: 1.6rem 4rem;
						border-top: 1px solid $border-input;
						display: flex;
						flex-direction: row;
						gap: 0.4rem;

						> :nth-child(1) {
							flex: 6;

							p {
								text-align: left;
								margin-bottom: 0;
								color: $btn-text-gray-default;

								&:nth-child(1) {
									margin-bottom: 0.8rem;
								}
							}
						}
						> :nth-child(2) {
							flex: 5;
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 0.8rem;

							> * {
								flex: 1;

								button {
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
		&.incomplete-div {
			background: $btn-error-50;
			border-color: #f97066;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1.6rem;
		}

		.job-dropdown {
			border: 1px solid $border-input;
			border-radius: 8px;
			padding: 10px;
			display: flex;
			align-items: center;
			width: 202px;
			cursor: pointer;
			span {
				font-weight: 400;
				color: $text-black;
				width: 152px;
			}
			&.disabled span {
				color: $text-gray-400;
			}
			i {
				font-size: 25px;
				color: $btn-text-gray-default;
				margin-left: 20px;
			}
			&.width-330 {
				width: 330px;
				span {
					width: 280px;
				}
			}
		}
		.filters-div {
			display: flex;
			justify-content: space-between;
			margin: 30px 0px 26px 0px;
		}
		.job-div {
			border: 1px solid $border-input;
			border-radius: 8px;
			padding: 16px 20px;
			margin-bottom: 16px;

			> .loading-component {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				position: absolute;
				background-color: rgba(255, 255, 255, 0.5);
				opacity: 0;
				visibility: hidden;
				transition: all 0.2s;
			}

			&.loading {
				position: relative;

				> .loading-component {
					opacity: 1;
					visibility: visible;
				}
			}

			.role-details {
				margin-left: 20px;
				p {
					color: $text-black;
				}
				.role-name {
					font-size: 1.25em;
					font-weight: 600;
				}
				.text-lite {
					font-size: 0.875em;
					color: $btn-text-gray-default;
					font-weight: 400;
				}
				.text-medium {
					font-weight: 600;
					font-size: 0.875em;
					margin-left: 5px;
				}
				.emp-update {
					font-size: 0.875em;
					color: #039855;
					margin-top: 10px;
				}
			}
			.compare-btn {
				border: 1px solid $btn-text-gray-default;
				color: $btn-text-gray-default;
				border-radius: 8px;
				font-weight: 600;
				font-size: 0.875em;
				padding: 8px 12px;
				cursor: pointer;
			}
			.bookmark-logo {
				margin-left: 20px;
				cursor: pointer;
				width: 3rem;
				height: 4rem;
			}
			.msg-div {
				min-width: max-content;
				padding: 6px 12px;
				font-size: 0.875em;
				font-weight: 700;
				border-radius: 8px;
				&.msg-green {
					color: #12b76a;
					background-color: rgba(7, 210, 0, 0.1);
				}
				&.msg-red {
					color: $btn-error-default;
					background-color: $btn-error-50;
				}
				&.msg-blue {
					color: #0868a5;
					background-color: #c5e8ff;
				}
				&.msg-neutral {
					color: $btn-primary-default;
					background-color: $dropdown-hover-bg;
				}
			}
			.applied-details {
				margin-top: 10px;
				span {
					color: $btn-text-gray-hover;
					font-size: 0.875em;
					font-weight: 600;
					margin-left: 6px;
				}
				.mar-left {
					margin-left: 20px;
				}
			}
		}
	}
	.coming-soon {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		align-items: center;
		justify-content: center;
		min-height: 30rem;

		&-label {
			display: flex;
			flex-direction: column;
			gap: 0.3rem;
			align-items: center;
		}
	}
}
@media only screen and (max-width: 960px) {
	.my-jobs-component {
		.container {
			padding: 10px;
		}
	}
}

// incomplete applications
.ia-comp {
	&-div {
		background: $white-color;
		border: 1px solid $border-input;
		border-radius: 12px;
		padding: 2.4rem;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		gap: 1.6rem;

		.ac {
			background-color: $white-color;
			border: 1px solid $border-input;
			border-radius: 8px;
			padding: 1.6rem;

			display: flex;
			gap: 1.6rem;

			& .company_logo {
				border-radius: 8px;
				height: 5.4rem;
				width: 5.4rem;
				object-fit: cover;
			}

			& .content {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 0.4rem;

				h3 {
					font-size: 2rem;
					font-weight: 600;
					margin-bottom: 0;
				}

				p {
					margin-bottom: 0;
					font-size: 1.4rem;
					color: $btn-text-gray-default;
					@include flex-all-center;
					justify-content: flex-start;
					gap: 0.4rem;

					strong {
						color: $text-black;
					}
				}
			}

			& .btns {
				align-self: flex-end;
				display: flex;
				gap: 0.8rem;
			}
		}
	}

	&-header {
		margin-bottom: 2.4rem;
		@include flex-all-center;
		justify-content: flex-start;
		gap: 1.6rem;

		p {
			font-size: 2rem;
			font-weight: 600;
		}
	}
}

// Dropdown style
.job-status-dropdown-options {
	background-color: white;
	border-radius: 0.8rem;
	padding: 0.8rem 0;
	box-shadow: -2px 0px 10px 0px rgba(5, 108, 242, 0.2);
	max-height: 50vh;
	overflow: auto;
	min-width: 330px;

	.job-status-dropdown-options-item {
		display: flex;
		align-items: center;
		padding: 0.8rem 1.6rem;
		color: #1d2939;
		font-weight: 400;
		font-size: 1.6rem !important;
		border: none;
		outline: none;
		background-color: white;
		transition: 100ms;
		width: 100%;
		text-decoration: none;

		&.active {
			cursor: auto;
		}

		&.active,
		&:hover {
			font-weight: 600;
			background-color: $dropdown-hover-bg;
			color: $text-black;
		}
	}
}
