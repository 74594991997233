.job-ads-listing__header-div {
	position: relative;
	display: flex;
	border-bottom: 1px solid #98a2b3;
	margin-top: 2rem;
	gap: 2rem;
	margin-bottom: 1rem;

	&__header-item {
		padding: 6px 10px;
		margin-bottom: -2.5px;
		font-weight: 600;
		color: #667085;
		cursor: pointer;
		transition: 0.2s;

		span {
			background-color: #fc9607;
			padding: 0px 8px;
			border-radius: 20px;
			margin-left: 10px;
			color: white;
			font-size: 1.4rem;
		}

		&.selected {
			color: #193560;
		}
	}

	&__border-bottom-blue {
		width: 120px;
		height: 4px;
		border-radius: 2px;
		background-color: #193560;
		position: absolute;
		display: flex;
		align-self: flex-end;
		bottom: -0.23rem;
		transition: 0.6s;
	}
}
