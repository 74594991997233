@import "../../colorvariable.scss";

.report-jobad-popup-modal {
	.body-item {
		display: grid;
		gap: 3rem;

		&-top {
			display: grid;
			justify-items: center;
			gap: 1.5rem;

			p {
				color: #1d2939;
				font-weight: 700;
				font-size: 20px;
			}
		}

		&-reason {
			& > span {
				color: #667085;
				font-weight: 500;
				font-size: 14px;
			}

			& > span.label {
				color: #667085;
				font-weight: 500;
				font-size: 14px;

				&::after {
					content: "*";
					color: red;
				}
			}
			.reason_option {
				display: grid;
				grid-template-columns: auto auto;
				gap: 1rem;
				padding-top: 1.5rem;

				.form-check {
					cursor: pointer;
					width: fit-content;

					label.form-check-label {
						cursor: pointer;
						color: #1d2939;
						font-weight: 400;
						font-size: 16px;
					}

					input.form-check-input {
						cursor: pointer;
						box-shadow: none;
						border: 1px solid #ccdaef;
						position: relative;

						&:checked {
							border: 1px solid #193560;

							&::before {
								width: 8px;
								height: 8px;
								background-color: #193560;
								content: "";
								position: absolute;
								top: 50%;
								left: 50%;
								border-radius: 50%;
								transform: translate(-50%, -50%);
							}
						}
					}
				}
			}
		}

		&-comment {
			span {
				color: #667085;
				font-weight: 500;
				font-size: 14px;
			}

			textarea.form-control {
				margin-top: 1.5rem;
				resize: none;
				font: inherit;
				padding: 1.5rem;

				&::placeholder {
					color: #98a2b3;
					font-weight: 400;
					font-size: 14px;
				}
			}

			& > div {
				display: flex;
				align-items: center;
				justify-content: space-between;

				span:first-child {
					font-weight: 400;
					color: red;
				}
			}
		}

		&-action {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 2rem;

			.J-btnComp {
				flex: 1;

				button {
					width: 100%;
					padding: 0.8rem;
				}
			}
		}
	}
}
