@import "./../../colorvariable.scss";
@import "../../mixin.scss";

.suc {
    width: 100%;
    min-height: 100vh;
    height: max-content;
    padding: 13rem 11.2rem 0rem 11.2rem;
    background: linear-gradient(to right, #F2F4F7 45%, white 0%);

    &.form {
        padding-top: 4.4rem;
    }

    &.drComp {
        background: linear-gradient(to right, $btn-primary-default 45%, white 0%);
        padding-top: 4rem;
    }

    @media only screen and (max-width: 450px) {
        padding: 3.2rem;
    }

    &-main {
        height: 100%;
        display: flex;

        @media only screen and (max-width: 450px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 5rem;
        }
    }

    &-left {
        &-content {
            & .logoSUC {
                padding-bottom: 8rem;
            }
        }

        &-progress {
            color: #ffffff;
            font-size: 1.6rem !important;
            display: flex;
            flex-direction: column;
            gap: 3.2rem;

            & label {
                padding-left: 1.3rem;
                font-weight: 500;
                @include text-xl;
            }
        }
    }

    &-right {
        padding: 1rem 0rem;

        @media only screen and (max-width: 450px) {
            padding: 1rem;
        }

        &-content {
            width: 100%;

            & h1 {
                width: 32rem;
                line-height: 140%;
                font-weight: 600;
                color: $text-black;
                @include display-sm;
            }

            & .sucInput {
                margin: 3.2rem 0rem;
            }

            & h3 {
                padding-bottom: 6.8rem;
                color: $btn-text-gray-default;
                font-weight: 500;
                @include text-md;
            }
        }

        &-form {
            & h1 {
                font-weight: 600;
                color: $text-black;
                text-align: center;
                @include display-sm;
            }

            & .formInput {
                gap: 2.4rem;
                display: flex;
                margin: 3.2rem 0rem;
                flex-direction: column;
            }

        }
    }
}

.multiComponent {
    height: 100%;
    & h1 {
        padding: 3.2rem 0rem;
        font-weight: 600;
        color: $text-black;
        @include display-sm;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
    }

    & .gender {
        font-weight: 400;
        color: $btn-text-gray-default;
        @include text-md;
    }

    &-btnContainer {
        display: flex;
        align-items: center;
        padding: 5.2rem 0rem;
    }
}