@import "./../../colorvariable.scss";
@import "../../mixin.scss";

.profile-comp {
	background-color: $bg-input-disable;
	padding: 4rem;
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	.pc-grids {
		display: grid;
		width: 100%;
		grid-template-columns: 1fr 1fr;
		column-gap: 0.8rem;
		row-gap: 2.8rem;

		@media only screen and (max-width: 960px) {
			grid-template-columns: 1fr;
		}
	}

	.pc-pic-cont,
	.pc-pic {
		width: 8rem;
		height: 8rem;
		border-radius: 50%;
	}

	.pc-pic-cont {
		cursor: pointer;
	}

	.pc-pic {
		object-fit: cover;
	}

	.pc-curr-occupation {
		color: $btn-text-gray-default;
		@include text-md;
		display: flex;
		flex-wrap: wrap;
		gap: 1.2rem;
	}

	.pc-username {
		color: $text-gray-400;
	}

	.divider {
		margin: 2.8rem 0;
	}

	.pc-skills {
		@include text-sm;
		color: $btn-text-gray-default;
		padding: 0.8rem;
		border-radius: 0.4rem;
		background-color: $text-gray-100;
		border: 1px solid $btn-outline-hover;
		@include flex-all-center;
	}

	.pc-lang-grid {
		@include text-sm;
		display: flex;
		flex-direction: column;
		// grid-template-columns: 2fr 2fr 2fr 1fr;
		overflow: auto;
		// align-items: center;

		&-header {
			color: $btn-text-gray-default;
			padding: 0 0.8rem;
			padding-bottom: 1.6rem;
		}

		&-item {
			white-space: nowrap;
			padding: 1.2rem 0.8rem;
			position: relative;
			width: 100%;
			height: 100%;
			border-bottom: 1px solid $border-bottom-dropdown;
			margin: auto 0;

			&:nth-last-child(1) {
				border-bottom: none;
			}
		}

		&.three-item {
			grid-template-columns: 2fr 2fr 1fr;

			.pc-lang-grid-item {
				&:nth-last-child(4) {
					border-bottom: 1px solid $border-bottom-dropdown;
				}
				&:nth-last-child(3),
				&:nth-last-child(2),
				&:nth-last-child(1) {
					border-bottom: none;
				}
			}
		}

		@media only screen and (max-width: 960px) {
			width: 100%;
		}
	}

	.gap-pc {
		gap: 2.8rem;
	}

	.pc-carousel {
		max-width: 100%;
		max-height: 100%;
	}

	.carousel-cont .carousel-card {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	.pc-carousel-card {
		cursor: pointer;
		background: $bg-input-disable;
		border: 1px solid $border-input;
		border-radius: 0.8rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 1.6rem 0.8rem;
		gap: 2rem;
	}

	.pcc-file-name {
		text-align: center;
		color: $text-black;
		@include text-sm;
		@include one-line-truncate;
	}

	.pc-dropdown-item {
		display: flex;
		align-items: center;
		gap: 1.2rem;
		color: $text-black;
		@include text-md;
	}
}

.exp-item-comp,
.profile-item-comp {
	display: flex;
	align-items: center;
	gap: 1.6rem;
	width: 100%;
	max-width: 100%;

	.pi-icon {
		min-width: 2.4rem;
		width: 2.4rem;
		height: 2.4rem;
		object-fit: cover;
	}
}

.exp-item-comp {
	align-items: flex-start;
}

.truncation-text-sm {
	white-space: pre-wrap;
	span {
		@include text-sm;
	}
}

.pc-form {
	display: flex;
	flex-direction: column;
	gap: 2.4rem;

	.J-radioInputComp {
		min-width: 12rem;
	}

	&-row {
		display: flex;
		gap: 1.2rem;
	}
}

.pc-pic-big-cont,
.pc-pic-big {
	width: 14.4rem;
	height: 14.4rem;
	border-radius: 50%;
	object-fit: cover;
}

.modal-info.confirmSkill {
	max-width: max-contnet;
}

// Other Resume Modal
.other-resume {
	.pc-form {
		gap: 1.4rem;
	}

	.pc-form-head {
		h4 {
			font-size: 1.8rem;
			font-weight: 600;
		}
	}

	.drop-area {
		background: #f9fafb;
		border: 2px solid #ccdaef;
		border-style: dashed;
		border-radius: 5px;
		padding: 2.5rem 1.5rem;

		display: flex;
		flex-direction: column;
		align-items: center;

		&--success {
			background: #ecfdf3;
			border-color: #32d583;
		}

		&--failed {
			background: #fef3f2;
			border-color: #f97066;
		}

		.placeholder-image {
			height: 18rem;
			width: 18rem;
		}

		p {
			text-align: center;
		}

		.file-name {
			font-size: 2rem;
			font-weight: 600;

			.check-icon {
				padding-left: 1rem;
				font-size: 3rem;
				color: #039855;
			}
		}

		.replace-file {
			label {
				cursor: pointer;
				font-weight: 600;
			}
		}
	}
	.upload-failed-msg {
		color: #f04438;
		font-size: 1.4rem;
		font-weight: 500;
	}
}

.profile-frame-comp .dropdown-item:active {
	border-color: unset;
}
.otherInfo {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	// white-space: nowrap;

	p {
		margin-bottom: 0;
	}

	p:not(:first-child) {
		color: $btn-text-gray-default;
	}

	p:not(:first-child)::before {
		content: "";
		height: 5px;
		width: 5px;
		background: $btn-text-gray-default;
		display: inline-block;
		vertical-align: middle;
		margin-inline-end: 1rem;
		border-radius: 50%;
	}
}
