@import "../../../colorvariable.scss";
@import "../../../mixin.scss";

// J-inputComp means Job-inputComponent
.J-inputComp {
	// width: 100%;
	// display: flex;
	// flex-direction: column;
	// justify-content: center;
	// align-items: center;
	&.password {
		margin-bottom: 0;
	}

	label {
		font-weight: 500;
		font-size: 1.4rem;
		transition: all 0.1s ease-in;
		margin-bottom: 0.8rem;
		color: $btn-text-gray-default;

		&.isRequired::after {
			content: "*";
			color: $btn-error-default;
		}
	}

	input {
		font-weight: 400;
		font-size: 1.6rem;
		padding: 1.2rem 4rem;
		background-color: $bg-White;

		&#special-field {
			padding-left: 7rem;
		}

		&#normal-field {
			padding-left: 1.2rem;
			padding-right: 8rem;
		}
		&.password-input {
			padding-left: 1.2rem;
		}
	}

	textarea {
		padding: 1rem 1.4rem;
		font-size: 1.6rem;
	}

	&-input {
		display: block;
		width: 100%;
		border: 1px solid $border-input;
		border-radius: 0.8rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

		&:focus {
			box-shadow: 0px 3px 0px $btn-outline-hover, 0px 1px 2px rgba(16, 24, 40, 0.05);
			border-color: $btn-primary-default;
			outline: 0;
		}

		&.field-error {
			border: 1px solid $btn-error-default;
		}
	}

	&-choice {
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		gap: 1rem;

		&-label {
			font-size: 1.6rem !important;
			margin-bottom: 0 !important;

			&.choice-field-error {
				color: $btn-error-default;
			}
		}

		&-option {
			accent-color: $btn-primary-default;
			transform: scale(1.3);
		}
	}

	&-location {
		background: url("../../../assets/svg/location.svg") 1.1rem 1.3rem / 2.2rem no-repeat;
	}

	&-search {
		background: url("../../../assets/svg/search.svg") 1.1rem 1.3rem / 2.2rem no-repeat;
	}

	&-college {
		background: url("../../../assets/svg/graduation_icon.svg") 1.1rem 1.3rem / 2.2rem no-repeat;
	}

	&-company {
		background: url("../../../assets/svg/company.svg") 1.1rem 1.3rem / 2.2rem no-repeat;
	}

	&-email {
		background: url("../../../assets/svg/mail.svg") 1.1rem 1.3rem / 2.2rem no-repeat;
	}

	&-dropdown {
		padding-left: 1rem !important;
		padding-right: 6.6rem !important;
	}

	&-rightBtn {
		position: absolute;
		width: 2.5rem;
		bottom: 1.2rem;
		right: 1rem;
	}

	&-tooltip {
		width: 1.8rem;
		margin-left: 0.6rem;
	}

	&-below {
		margin: 0;
		padding: 0;
		font-size: 1.4rem;
		font-weight: 400;
		margin-top: 0.4rem;
		color: $btn-text-gray-default;
	}

	&-curSalDropdown {
		position: absolute;
		top: 1px;
		left: 1px;
		height: 95%;
		width: 6.2rem;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border: none;
		padding: 0.5rem;

		&:active,
		&:focus {
			outline: none;
		}

		&:focus-visible {
			// border: 1px solid $btn-text-gray-disable;
		}
	}

	.warningIcon {
		filter: brightness(0) saturate(100%) invert(32%) sepia(22%) saturate(7288%)
			hue-rotate(344deg) brightness(105%) contrast(88%);
	}

	.error {
		color: $btn-error-default;
	}

	.borError {
		border: 1px solid $btn-error-default;
		border-radius: 0.8rem;

		&:focus {
			box-shadow: 0px 3px 0px #fee4e2, 0px 1px 2px rgba(16, 24, 40, 0.05);
		}
	}

	.disable {
		border: 1px solid $btn-text-gray-disable;
		background-color: $bg-input-disable;
		border-radius: 0.8rem;
		cursor: not-allowed;
	}

	.onOffPassword {
		position: absolute;
		padding: 1rem;
		right: 0.4rem;
		top: 50%;
		transform: translateY(-50%);
	}
}

.J-radioInputComp {
	display: flex;
	align-items: center;
	padding-left: 3rem;

	label.J-radioInputComp-label {
		@include text-md;
		color: $text-black;
		margin-bottom: 0;
		padding-left: 1rem;

		&.field-error {
			color: $btn-error-default;
		}
	}
	&-input {
		width: 1.6rem;
		height: 1.6rem;
		margin-left: -3rem;
		accent-color: $btn-primary-default;
	}
}

.helper-text {
	color: $btn-text-gray-default;
	font-weight: 400;
	font-size: 1.4rem;
	margin-bottom: 0.1rem;

	&.error-text {
		color: $btn-error-default;
	}
}
