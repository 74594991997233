@import "./../../colorvariable.scss";
@import "../../mixin.scss";

.rpc {
	width: 100%;
	height: 100vh;

	&-main {
		width: 50%;
		height: 100%;
		padding: 3.2rem 2.4rem;
		margin-top: 4rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border: 1px solid $border-input;
		border-radius: 1.6rem;

		@media only screen and (max-width: 450px) {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		& h1 {
			font-weight: 600;
			color: $text-black;
			padding: 3.2rem 0rem 1.2rem 0rem;
			@include display-xs;
		}

		& h3 {
			font-weight: 500;
			color: $text-black;
			@include text-md;
		}

		&-form {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 2rem;
			margin-block: 3.2rem;

			& .form-group {
				margin-bottom: 0;
			}

			& .J-inputComp-input {
				padding-left: 2rem;
			}
		}

		&-input {
			flex: 1;
			width: 100%;
		}

		&-button {
			padding-top: 3.2rem;
		}
	}

	&-success {
		margin-top: 10rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 4rem;

		border: 1px solid #ccdaef;
		padding: 3.2rem;
		border-radius: 2rem;

		&-desc {
			text-align: center;

			h3 {
				font-size: 2.5rem;
				font-weight: 600;
			}

			p {
				margin-top: 2rem;
				font-size: 2rem;
			}
		}

		.continue-btn {
			width: 55rem;
		}
	}
}
