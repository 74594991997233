@import "../../../colorvariable.scss";
@import "../../../mixin.scss";

.profile-frame-comp {
  padding: 2.4rem;
  border-radius: 1.2rem;
  border: 1px solid $border-input;
  background-color: white;

  .pfc-title-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.8rem;

    .pfc-title {
      @include display-xs;
      color: $text-black;
      font-weight: 600;
    }
  }

  .pfc-btn {
    padding: 0.8rem;
    @include flex-all-center;
    position: relative;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 50%;
    transition: 100ms;

    &:hover {
      background-color: #F2F4F7;
    }
  }
}