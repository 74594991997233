@import "./../../colorvariable.scss";

.page-wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.header-content-wrapper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		background: $gray-50;
	}
}
