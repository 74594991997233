/*
  mixin is used to reuse the same styling
  Usage: just use @include [mixin name] in the specified styling
  example:
  .something {
    @include flex-all-center;
  }
*/

@mixin flex-all-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin one-line-truncate {
	width: 100%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin line-clamp-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin line-clamp-3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin icon-standby-hover-def {
	.icon-standby,
	.icon-hover {
		@include absolute-center;
		transition: 100ms;
	}

	.icon-standby {
		opacity: 1;
	}

	.icon-hover {
		opacity: 0;
	}
}

@mixin icon-hover-show {
	.icon-standby {
		opacity: 0;
	}

	.icon-hover {
		opacity: 1;
	}
}

@mixin hide-scrollbar {
	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

@mixin display-xl {
	font-size: 7.2rem !important;
}
@mixin display-2xl {
	font-size: 6rem !important;
}
@mixin display-lg {
	font-size: 4.8rem !important;
}
@mixin display-md {
	font-size: 3.6rem !important;
}
@mixin display-sm {
	font-size: 3rem !important;
}
@mixin display-xs {
	font-size: 2.4rem !important;
}
@mixin text-xl {
	font-size: 2rem !important;
}
@mixin text-lg {
	font-size: 1.8rem !important;
}
@mixin text-md {
	font-size: 1.6rem !important;
}
@mixin text-sm {
	font-size: 1.4rem !important;
}
@mixin text-xs {
	font-size: 1.2rem !important;
}

@mixin truncate($line: 1) {
	overflow: hidden;
	display: -webkit-box;
	overflow-wrap: break-word;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $line;
}

@mixin size($height: auto, $width: auto) {
	height: $height;
	width: $width;
}
