@import "../../../../colorvariable.scss";

.icon-status-container {
	height: 8rem;
	width: 8rem;
	border-radius: 28rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-width: 1rem;
	border-style: solid;

	svg {
		width: 2.8rem;
		height: 2.8rem;
	}

	&.error {
		border-color: $btn-error-50;
		background-color: $icon-error;

		svg {
			color: #b42318;
		}
	}

	&.success {
		background-color: $darkGreenColor;
		border-color: $icon-success;

		svg {
			color: #fff;
		}
	}
}
