@import "../../colorvariable.scss";
@import "../../mixin.scss";

.cp-container {
	height: 100%;
	background: $gray-50;
	padding-block: 3.2rem;
	@include flex-all-center;

	.cp {
		border: 1px solid $border-input;
		background: $white-color;
		width: 67.4rem;
		padding: 3.2rem 2.4rem;
		border-radius: 16px;

		display: flex;
		flex-direction: column;
		justify-content: stretch;
		gap: 3.2rem;

		&-header {
			text-align: center;

			h3 {
				margin-top: 3.2rem;
				margin-bottom: 1.2rem;
				font-size: 2.4rem;
				font-weight: 600;
			}

			p {
				font-size: 1.6rem;
				font-weight: 500;
			}
		}

		&-body {
			display: flex;
			flex-direction: column;
			gap: 2.4rem;
		}

		&-footer {
			@include flex-all-center;
			justify-content: flex-start;
			gap: 0.8rem;
		}
	}
}
