@import "../../../colorvariable.scss";
@import "../../../mixin.scss";

.modal-report-job {
	.mrj {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin: 0rem 2.4rem;
		padding: 3.2rem 0rem;

		&-head {
			padding-bottom: 3.2rem;
			display: flex;
			flex-direction: column;
			align-items: center;

			& h3 {
				padding-top: 3.2rem;
				color: $text-black;
				font-weight: 700;
				@include text-xl;
			}
		}

		&-radio {
			display: flex;
			flex-direction: column;
			padding-bottom: 3.2rem;

			& h5 {
				font-weight: 500;
				color: $btn-text-gray-default;
				@include text-sm;
			}

			&-content {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: 1.6rem;

				& .left,
				& .right {
					display: flex;
					flex-direction: column;

					label {
						width: 100%;
						padding: 1rem 1.6rem;
					}

					label > input {
						margin-right: 1.4rem;
					}
				}
			}
		}

		&-button {
			display: flex;
			justify-content: space-between;
			padding-top: 3.2rem;
		}
	}

	.ms {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin: 3.2rem 2.4rem;

		&-head {
			display: flex;
			flex-direction: column;
			align-items: center;

			& h3 {
				padding-top: 4.2rem;
				color: $text-black;
				font-weight: 700;
				margin-bottom: 0;
				@include text-xl;
			}

			& h5 {
				padding-top: 1.6rem;
				font-weight: 400;
				color: $btn-text-gray-default;
				text-align: center;
				line-height: 2.2rem;
				margin-bottom: 0;
				@include text-md;
			}
		}

		&.m-email {
			margin: 0rem;
			padding: 2.4rem 1.2rem;
		}

		&.m-delete {
			margin: 0rem;
			padding: 0rem;
		}
	}

	& .mButton {
		display: flex;
		justify-content: center;
		gap: 3.2rem;
		margin-top: 3.2rem;

		& button {
			padding: 1.2rem 5rem;
		}
	}
}

// Modal Description
.mDesc {
	display: flex;

	&-head {
		padding: 2.4rem;
		font-weight: 600;
		@include text-xl;
	}

	&-body {
		height: 60vh;
		overflow-y: scroll;
		padding: 2.4rem 2.4rem 1.2rem 2.4rem;

		& h4 {
			font-weight: 600;
			padding-bottom: 1.2rem;
			color: $home-title-color;
			@include text-md;

			&.second {
				padding-top: 3.2rem;
			}
		}

		& li {
			color: $btn-text-gray-default;
			font-weight: 400;
			@include text-sm;
		}
	}

	&-footer {
		padding: 1.2rem;
	}
}

.modal-info {
	max-width: 44.4rem;

	.modal-content {
		--bs-modal-border-radius: 1.6rem;
	}

	.modal-body {
		--bs-modal-padding: 3.2rem 2.4rem;
		max-height: calc(80vh - 20rem);
		overflow: auto;
	}
}

.modal-image-icon {
	width: 9.6rem;
	height: 9.6rem;
	object-fit: cover;
}

// Modal Login
.login-modal {
	.modal-body {
		padding: 3.2rem 2.4rem;
		position: relative;
	}

	.lm {
		& h4 {
			margin-bottom: 1.2rem;
			font-weight: 600;
			color: $text-black;
			@include text-md;
		}

		& span {
			font-weight: 400;
			@include text-sm;
		}

		&-password {
			& span {
				color: $bg-dark-blue;
				font-weight: 400;
				@include text-sm;
			}
		}

		&-footer {
			& .title,
			& .link {
				font-weight: 600;
				@include text-md;
			}

			& .title {
				color: $text-black;
				margin-bottom: 0.8rem;
			}

			& .link {
				color: $btn-primary-default;
			}
		}
	}

	.mb-2rem {
		margin-bottom: 2rem;
	}

	.me-1p8rem {
		margin-right: 1.8rem;
	}

	.btn-cta-secondary {
		color: $bg-dark-blue;

		&:hover {
			color: $bg-dark-blue;
		}
	}

	.J-inputComp input {
		@include text-sm;
		padding-left: 1.2rem;
	}
}

// Modal Form
.modal-form {
	max-width: 65rem;

	.modal-content {
		--bs-modal-border-radius: 1.6rem;
	}

	.modal-header {
		--bs-modal-header-border-width: 2px;
		border-color: $btn-text-gray-disable;
		padding: 2.4rem 3.2rem;
		background-color: $bg-input-disable;
		border-radius: 1.6rem 1.6rem 0 0;
	}

	.modal-body {
		--bs-modal-padding: 3.2rem;
		max-height: calc(80vh - 20rem);
		overflow: auto;
	}

	.modal-title {
		@include text-xl;
		color: $text-black;
		font-weight: 600;
	}

	.modal-footer {
		--bs-modal-footer-border-width: 2px;
		--bs-modal-footer-gap: 0;
		border-color: $btn-text-gray-disable;
		padding: 2.4rem 3.2rem;
	}
}
