@import "../../colorvariable.scss";
@import "../../mixin.scss";

.settings {
	height: 100%;
	padding-block: 3.2rem;
	@include flex-all-center;

	&-container {
		width: 67.5rem;

		.dc-comp {
			.data-div {
				display: flex;
				flex-direction: column;
				gap: 2.4rem;

				p {
					margin-bottom: 0;
				}

				.header {
					p {
						margin-top: 1rem;
					}
				}

				.profile {
					display: flex;
					align-items: center;
					gap: 1.6rem;
					border: 1px solid $border-input;
					padding: 2.4rem;
					border-radius: 1.2rem;

					img {
						@include size(8rem, 8rem);
						border-radius: 50rem;
						object-fit: cover;
					}
				}
				.col-layout {
					display: flex;
					flex-direction: column;
					gap: 2.4rem;

					&.gap-1p6 {
						gap: 1.6rem;
					}
				}

				.row-layout {
					display: flex;
					align-items: center;
					gap: 0.8rem;
				}

				.form-group {
					margin-bottom: 0 !important;

					label {
						font-size: 1.6rem;
						font-weight: 400;
						color: $btn-text-gray-default;
					}
				}
			}
		}
	}

	.data-div {
		background-color: $white-color;
		border-radius: 12px;
		padding: 24px;
		border: 1px solid $border-input;

		h3 {
			font-size: 2.4rem;
			font-weight: 600;
			margin-bottom: 0;
		}

		.header-div {
			display: flex;
			gap: 2.4rem;
			border-bottom: 1px solid $border-input;
			margin-top: 36px;
			height: 36px;

			.header-item {
				width: max-content;
				padding: 8px 10px;
				margin-bottom: -2.5px;
				font-weight: 600;
				color: $btn-text-gray-default;
				cursor: pointer;
				transition: 0.2s;
				text-align: center;
				position: relative;

				&::after {
					content: "";
					display: block;
					background: transparent;
					width: 0%;
					height: 4px;
				}

				&.selected {
					color: $label-color-blue;
					font-weight: 600;

					&::after {
						content: "";
						display: block;
						background: $label-color-blue;
						border-radius: 2px;
						width: 100%;
						height: 4px;
						position: absolute;
						left: -1px;
						top: 90%;
						transition: width 0.2s ease-in-out;
					}
				}
			}
		}

		.content-div {
			margin-top: 4rem;

			.hr-line {
				margin-block: 2.4rem;
				color: $border-bottom-dropdown;
			}

			.acc-tab {
				h4 {
					font-size: 2rem;
					font-weight: 600;
					margin-bottom: 1.2rem;
				}

				.my-account {
					display: flex;
					align-items: center;
					gap: 1.2rem;

					p {
						margin-bottom: 0;
						font-size: 1.6rem;
						font-weight: 400;
						cursor: pointer;
					}

					.separator {
						color: $btn-text-gray-default;
					}

					.deactivate {
						color: $btn-text-gray-default;
					}

					.close {
						color: $btn-error-default;
					}
				}
			}

			.noti-tab {
				.noti {
					width: 100%;
					display: flex;
					justify-content: space-between;
					gap: 5.2rem;

					&-content {
						flex: 1;

						h4 {
							font-size: 1.6rem;
							font-weight: 600;
						}

						p {
							font-size: 1.4rem;
							font-weight: 400;
							color: $btn-text-gray-default;
							margin-bottom: 0;
						}

						.reference {
							color: $btn-primary-default;
							cursor: pointer;
							font-weight: 500;
							margin-top: 0.4rem;
						}
					}

					.tComp {
						width: unset !important;

						&-switch {
							margin: 0;
						}
					}
				}

				&-footer {
					display: flex;
					gap: 2.8rem;
					justify-content: space-between;
					align-items: center;

					button {
						width: 100%;
					}
				}
			}
		}
	}
}
