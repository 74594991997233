.typography {
	&__title {
		&_h1,
		&_h2,
		&_h3,
		&_h4,
		&_h5,
		&_h6 {
			&.bold {
				font-weight: 700;
			}

			&.semibold {
				font-weight: 600;
			}

			&.medium {
				font-weight: 500;
			}

			&.regular {
				font-weight: 400;
			}
			&.xs {
				font-size: 2.4rem;
				line-height: 140%;
			}

			&.sm {
				font-size: 3rem;
				line-height: 140%;
			}

			&.md {
				font-size: 3.6rem;
				line-height: 140%;
				letter-spacing: -2%;
			}

			& .lg {
				font-size: 4.8rem;
				line-height: 140%;
				letter-spacing: -2%;
			}

			&.xl {
				font-size: 6rem;
				line-height: 140%;
				letter-spacing: -2%;
			}

			&.xlxl {
				font-size: 7.2rem;
				line-height: 140%;
				letter-spacing: -2%;
			}
		}
	}

	&__text {
		margin-bottom: 0;
		&.bold {
			font-weight: 700;
		}

		&.semibold {
			font-weight: 600;
		}

		&.medium {
			font-weight: 500;
		}

		&.regular {
			font-weight: 400;
		}

		&.xl {
			font-size: 2rem;
			line-height: 140%;
			letter-spacing: -2%;
		}

		&.lg {
			font-size: 1.8rem;
			line-height: 140%;
			letter-spacing: -2%;
		}

		&.md {
			font-size: 1.6rem;
			line-height: 140%;
			letter-spacing: -2%;
		}

		&.sm {
			font-size: 1.4rem;
			line-height: 140%;
			letter-spacing: -2%;
		}

		&.xs {
			font-size: 1.2rem;
			line-height: 140%;
			letter-spacing: -2%;
		}
	}

	&__single-line {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
