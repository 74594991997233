.empty-state-cont {
	display: flex;
	flex-direction: column;
	gap: 3.2rem;
	align-items: center;
	min-height: 30rem;

	.J-btnComp button {
		padding: 0.8rem 2.4rem;
		font-size: 1.4rem;
		font-weight: 600;
	}
}
