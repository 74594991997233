@import "../../../colorvariable.scss";
@import "../../../mixin.scss";

// J-cardComp means Job-cardComponent
.J-cardComp {
	width: 100%;
	height: max-content;
	border: 1px solid $border-input;
	border-radius: 0.8rem;
	padding: 1.4rem;
	background-color: $bg-White;
	transition: all 0.1s ease-in;

	&.active {
		border-color: $btn-primary-default;
		outline: 1px solid $btn-primary-default;
	}

	&:hover,
	&.active {
		box-shadow: 0 0.7rem 1.3rem 0 rgba(0, 0, 0, 0.11);
	}

	@media screen and (max-width: 425px) {
		width: 100%;
	}

	&-icon > img {
		margin-bottom: 1.9rem;
	}

	&-title {
		@include text-xl;
		font-weight: 600;

		& p {
			margin-top: 0.4rem;
			color: $btn-text-gray-default;
		}
	}

	&-region {
		@include text-sm;
		font-weight: 600;
		color: $btn-text-gray-default;
		margin: 1.6rem 0rem;
	}

	&-postedAt {
		@include text-xs;
		margin-bottom: 1.6rem;
		font-weight: 400;
		color: $btn-text-gray-default;
	}

	&-btn {
		display: flex;
		align-items: center;
	}
}

.J-pubPriv {
	display: flex;
	padding: 1.6rem 2rem;
	border: 1px solid $border-input;
	border-radius: 0.8rem;
	background-color: $bg-White;
	transition: all 0.1s ease-in;

	& .ppLeft {
		width: max-content;
		padding-right: 1.2rem;
		display: flex;
	}

	& .ppRight {
		width: 100%;

		& h1 {
			font-weight: 500;
			color: $text-black;
			@include text-xl;
		}

		& h4 {
			font-weight: 400;
			color: $btn-text-gray-default;
			line-height: 2rem;
			@include text-sm;
		}
	}

	&.active {
		border: 1.5px solid $btn-primary-default;
		box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.11);
	}
}

.widthCard {
	width: 100%;
}

.px-8rem {
	padding: 0rem 0.8rem !important;
}
.J-FileCard {
	display: flex;
	padding: 0.8rem;
	gap: 0.8rem;
	border-radius: 0.8rem;
	border: 1px solid $border-input;
	background-color: $bg-input-disable;
	align-items: center;
	overflow: hidden;
	position: relative;

	.file-name {
		width: 20px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	&-UpStatus {
		position: relative;
		width: 3.2rem;
		height: 3.2rem;
	}

	&-Spinner,
	&-Success {
		@include absolute-center;
		transition: 100ms;
		opacity: 0;

		&.show {
			opacity: 1;
		}
	}

	&-Spinner .spinner-border {
		--bs-spinner-border-width: 0.25rem;
		color: #3e6dbc;
	}

	.loader-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		& .loader-container {
			background: #f9fafb;
			text-align: center;
			opacity: 0.5;

			img {
				height: 5rem;
			}
		}
	}
}
