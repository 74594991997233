@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./colorvariable.scss";
@import "./mixin.scss";
@import "./variables.scss";

// $notosans-fontface-path: "fonts/Noto/";
// @import "notosans-fontface/scss/notosans-fontface";

// --------- CSS RESET STARS HERE --------- //
// ---- Please refrain changing this unless necessary ---- //
:root {
	width: 100%;

	--bs-body-color: $label-color-dark-gray-2;
	--toastify-toast-width: 62.6rem;
}

:host {
	font-family: "Noto Sans", sans-serif;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	margin: 0;
	font-family: "Noto Sans", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}

#root,
#__next {
	isolation: isolate;
}

html {
	// 1 REM = 10 PX
	// Notice that we use different rem value as portal sekolah app
	font-size: 62.5%;

	@media only screen and (max-width: 960px) {
		font-size: 50%;
	}
}

body,
.btn {
	font-size: 1.6rem;
	font-weight: 500;
	line-height: 140%;
	color: $text-black;
}

html,
body {
	height: 100%;
}

.Toastify__toast {
	gap: 1.6rem;
	padding: 1.2rem 1.6rem;
	border-radius: 0.8rem;
	border: 2px solid;
	&-body {
		gap: 1.6rem;
		padding: 0;
		font-size: 1.4rem;
		& > div:nth-child(2)::before {
			font-weight: bold;
			display: block;
			font-size: 1.6rem;
		}
	}
	&-icon {
		width: 4.8rem;
		margin-inline-end: 0;
	}
	&.Toastify__toast-theme--light.Toastify__toast--info,
	&.Toastify__toast-theme--light.Toastify__toast--success {
		background-color: #ecfdf3;
		color: #039855;
		border-color: #039855;

		& > .Toastify__toast-body div:nth-child(2)::before {
			// content: "Success";
			color: #027a48;
		}

		& > .Toastify__toast-body div:last-child {
			margin-top: 2rem;
		}
	}
	&.Toastify__toast-theme--light.Toastify__toast--warning {
		background-color: #fffaeb;
		color: #dc6803;
		border-color: #dc6803;

		& > .Toastify__toast-body div:nth-child(2)::before {
			// content: "Warning";
			color: #b54708;
		}

		& > .Toastify__toast-body div:last-child {
			margin-top: 2rem;
		}
	}
	&.Toastify__toast-theme--light.Toastify__toast--error {
		background-color: #fef3f2;
		color: #d92d20;
		border-color: #d92d20;

		& > .Toastify__toast-body div:nth-child(2)::before {
			// content: "Error";
			color: #b42318;
		}

		& > .Toastify__toast-body div:last-child {
			margin-top: 2rem;
		}
	}
	.J-iconComp-content {
		padding: 1.2rem;
	}
}

.borRad-left {
	border-top-left-radius: 0.8rem;
}

.borRad-right {
	border-top-right-radius: 0.8rem;
}

// Inline Padding multiple of four
.padTop-8 {
	padding-top: 0.8rem;
}
.padTop-16 {
	padding-top: 1.6rem;
}
.padBot-16 {
	padding-bottom: 1.6rem;
}
.padBot-24 {
	padding-bottom: 2.4rem;
}
.padX-44 {
	padding-left: 4.4rem !important;
	padding-right: 4.4rem !important;
}
.padTop-32 {
	padding-top: 3.2rem;
}
.padBot-32 {
	padding-bottom: 3.2rem;
}
.marLeft-auto {
	margin-left: auto;
}
.marRight-auto {
	margin-right: auto;
}

.rotate180d {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.toggle-switch,
.toggle-switch {
	transform: scale(1.3);

	&.modal-togle {
		transform: scale(1.3);
		border: 2x solid black;
	}
}

.toggle-switch {
	cursor: pointer;
	text-indent: -9999px;
	width: 33px;
	height: 18px;
	background: #bec4cf;
	display: block;
	border-radius: 100px;
	position: relative;
	margin-bottom: 0;
	// &.revert {
	// 	transform: scale(-1.2);
	// }
}

.toggle-switch:after {
	content: "";
	position: absolute;
	top: 2px;
	left: 2px;
	width: 14px;
	height: 14px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked + .toggle-switch {
	background: #1abf73;
}

input:checked + .toggle-switch:after {
	left: calc(100% - 2px);
	transform: translateX(-100%);
}

.accordion {
	--bs-accordion-active-bg: white;
}
.accordion-item {
	border: none;
}
.accordion-button {
	padding: 0;

	&:not(.collapsed) {
		box-shadow: none;
	}
}
.accordion-button::after {
	display: none;
}

// --------- CSS RESET ENDS HERE --------- //

// --------- Styling declarations --------- //

h1 {
	font-size: 4rem;
}
h2 {
	font-size: 3rem;
}
h3 {
	font-size: 2rem;
}
h4 {
	font-size: 1.5rem;
}
h5 {
	font-size: 1rem;
}
p,
span,
button {
	font-size: 1.6rem;
}

.font-italic {
	font-style: italic;
}

#root {
	background-color: white;
}

.flex-all-center {
	@include flex-all-center;
}

.text-label-blue-gradient {
	background: linear-gradient(265.71deg, #1e63be 0%, #4a8de5 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}
.text-label-primary {
	color: $btn-primary-default !important;
}
.text-label-dark-blue {
	color: $label-color-dark-blue !important;
}
.text-label-black {
	color: $text-black !important;
}
.text-label-gray-300 {
	color: $text-gray-300 !important;
}
.text-label-gray-400 {
	color: $text-gray-400 !important;
}
.text-label-gray {
	color: $btn-text-gray-default !important;
}
.text-label-dark-gray {
	color: $label-color-dark-gray !important;
}
.text-label-dark-gray-2 {
	color: $label-color-dark-gray-2 !important;
}

.font-weight-medium {
	font-weight: 500;
}
.font-weight-semibold {
	font-weight: 600;
}

.display-xl {
	font-size: 7.2rem !important;
}
.display-2xl {
	font-size: 6rem !important;
}
.display-lg {
	font-size: 4.8rem !important;
}
.display-md {
	font-size: 3.6rem !important;
}
.display-sm {
	font-size: 3rem !important;
}
.display-xs {
	font-size: 2.4rem !important;
}
.text-xl {
	font-size: 2rem !important;
}
.text-lg {
	font-size: 1.8rem !important;
}
.text-md {
	font-size: 1.6rem !important;
}
.text-sm {
	font-size: 1.4rem !important;
}
.text-xs {
	font-size: 1.2rem !important;
}

.text-400 {
	font-weight: 400;
}
.text-500 {
	font-weight: 500;
}
.text-600 {
	font-weight: 600;
}

.btn-cta-primary,
.btn-cta-secondary {
	border-radius: 0.8rem;
	font-weight: 700;
	padding: 1.2rem 1.6rem;
	transition: 100ms;
	font-size: 1.5rem;

	.btn-icon {
		width: 2.4rem;
		height: 2.4rem;
		margin-right: 1.2rem;
		position: relative;
		display: inline-flex;
		vertical-align: middle;

		@include icon-standby-hover-def;
	}

	&.btn-sm {
		font-size: 1.2rem;
		padding: 0.8rem 1.2rem;

		.btn-icon {
			width: 1.6rem;
			height: 1.6rem;
			margin-right: 0.8rem;
		}
	}
}

.btn-cta-primary {
	color: white;
	background: linear-gradient(265.71deg, #1e63be 0%, #4a8de5 100%);

	&:focus,
	&:hover {
		background: linear-gradient(265.71deg, $blue-primary 0%, $blue-primary 100%);
		color: white;
	}
	&:active {
		color: white;
		background: linear-gradient(265.71deg, $label-color-blue 0%, $label-color-blue 100%);
		border: 1px solid $bg-container;
		outline: 1px solid $bg-container;
	}
	&:disabled {
		color: $label-color-blue;
		border: none;
		background: linear-gradient(265.71deg, $light-dark-green 0%, $light-dark-green 100%);
	}
}

.btn-cta-secondary {
	color: $bg-light-blue;
	border: 1px solid $bg-light-blue;

	&:focus,
	&:hover {
		border: 1px solid $bg-light-blue;
		background: $bg-container;
		color: $bg-light-blue;
		@include icon-hover-show;
	}
	&:active {
		background: #056cf2;
		color: white;
	}
	&:disabled {
		color: $label-color-gray;
		border-color: $label-color-gray;
		background: $bg-second-light;
	}
}

.btn-show-more {
	@include flex-all-center;
	width: 100%;
	padding: 1.2rem;
	font-size: 1.4rem;
	color: $bg-dark-blue;
	font-weight: 500;
	border-radius: 0.8rem;

	&:hover {
		background-color: darken($bg-container, 5%);
	}
}

.cursorPointer {
	cursor: pointer;
}

.form-group {
	margin-bottom: 2.4rem;

	label {
		margin-bottom: 0.8rem;
		font-weight: 500;
		font-size: 1rem;
	}

	.form-control {
		border: 1px solid #d0d5dd;
		padding: 1.6rem;
		border-radius: 0.8rem;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: $label-color-dark-gray-2;
		font-weight: 500;

		&::placeholder {
			color: $label-color-gray;
		}
		&:active,
		&:focus {
			border-color: $label-color-dark-blue;
			outline: none;
			box-shadow: none;
		}
		&.error {
			border-color: $bg-orange;
		}
	}

	.error-txt {
		font-size: 1rem;
		margin-top: 0.8rem;
		color: $bg-orange;
	}

	.pwd-eye {
		position: absolute;
		padding: 1rem;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.form-check-input:checked[type="radio"] {
	background-color: transparent;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%230d6efd'/%3e%3c/svg%3e");
}

.divider {
	width: 100%;
	margin: 2.4rem 0;
	height: 1px;
	background-color: $border-input;
}

.vertical-divider {
	width: 2px;
	margin: 0 2.4rem;
	height: 100%;
	background-color: $border-bottom-dropdown;
	padding: 0;
}

.error-msg {
	width: 100%;
	padding: 0.8rem 1.2rem;
	background-color: $light-red;
	font-size: 1.5rem;
	font-weight: 700;
	color: $bg-orange;
	margin-bottom: 2.4rem;
	border-radius: 0.8rem;
}

.search-bar {
	height: 4.8rem;
	flex: 1;
	position: relative;

	input {
		width: 100%;
		height: 100%;
		padding: 1.55rem 1.2rem 1.55rem 4.8rem;
		line-height: 1.5;
		border-radius: 0.8rem;
		background-color: $bg-container;
		border: 1px solid $light-dark-green;
		transition: 50ms;
		outline: none;
		color: $label-color-dark-gray-2;

		&:focus,
		&:active,
		&:not(:placeholder-shown) {
			background-color: white;
		}

		&:focus {
			background-color: white;
			border-color: $bg-light-blue;
		}
	}

	.searchIcon {
		position: absolute;
		left: 1.2rem;
		top: 50%;
		transform: translateY(-50%);
	}
}

.placeholder-glow .placeholder {
	animation: Gradient 2.25s ease infinite;
}

.placeholder {
	border-radius: 0.6rem;
	margin-right: 0.5rem;
	background-color: #f1efef;
}

.pulsate {
	background: linear-gradient(90deg, #f1efef, #f9f8f8, #f1efef, #f9f8f8);
	background-size: 400% 400%;
	animation: Gradient 2.25s ease infinite;
	cursor: wait;
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0% 50%;
	}
}

.mw-0 {
	min-width: 0;
}
.mw-std {
	min-width: 1.6rem;
}
.mw-2p4rem {
	min-width: 2.4rem;
}
.mh-0 {
	min-height: 0;
}

.facilities-cont {
	display: grid;
	gap: 1.6rem;
	grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));

	&-item {
		padding: 1.6rem;
		color: $label-color-dark-gray-2;
		font-size: 1.5rem;
		box-shadow: 0px 4px 4px 0px #056cf20d;
		border: 1px solid #f2f7ff;
		font-weight: 500;
		border-radius: 0.8rem;
		background-color: white;
		padding-left: 3rem;
		text-indent: -2rem;

		&:before {
			content: "";
			display: inline-block;
			margin-right: 1rem;
			margin-top: 0.625rem;
			width: 1rem;
			height: 1rem;
			min-width: 1rem;
			border-radius: 50%;
			background-color: $bg-second-orange;
		}
	}

	&-item.no-before {
		padding: 2.2rem 1.6rem;
		text-align: center;
		padding-left: 0;
		text-indent: 0;

		&::before {
			content: "";
			display: inline-block;
			margin-right: 0;
			width: 0;
			height: 0;
			border-radius: 0;
			margin-top: 0;
			background-color: transparent;
		}
	}
}

.fav-button {
	padding: 1.2rem 1.6rem;
	box-shadow: 0px 6px 10px 0px #056cf20d;
	background-color: white;
	border: 1px solid $bg-container;
	border-radius: 0.8rem;
	color: $label-color-dark-gray-2;
}

.full-width-banner {
	width: 100%;
	height: 100%;
	max-height: 75vh;
	object-fit: cover;
}

.back-arrow {
	transform: rotate(225deg);
}
.down-arrow {
	transform: rotate(145deg);
}

.quote-cont {
	width: 100%;

	@media screen and (min-width: 640px) {
		max-width: 64rem;
	}
}

.quote-desc {
	position: relative;
	font-size: 1.6rem;

	p {
		font-size: 1.6rem;
	}

	&::before {
		content: "‟";
		position: absolute;
		font-size: 10rem;
		color: $label-color-dark-blue;
		top: -5rem;
		left: -6rem;
	}
}
section.detail-section {
	padding: 2.4rem 0.75rem;
	margin-bottom: 8rem;

	.header {
		font-weight: 700;
		color: $label-color-dark-gray-2;
		margin-bottom: 3.2rem;
	}
}

.as-label {
	font-weight: 500;
	color: $btn-text-gray-default;
	padding-bottom: 0.8rem;
	font-size: 1.4rem !important;

	&.isRequired::after {
		content: "*";
		color: $btn-error-default;
	}
}

.btn-scrollTop {
	padding: 0;
	width: 4.8rem;
	height: 4.8rem;
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	z-index: 99;
	opacity: 1;
	visibility: visible;
	transition: 200ms;
	background-color: $label-color-blue;
	border-color: $label-color-blue;
	border-radius: 0.8rem;
	box-shadow: 5px 1px 21px rgba(51, 51, 51, 0.3);
	// color: white;

	&:active {
		background-color: $btn-primary-default !important;
	}

	&.hide {
		visibility: hidden;
		opacity: 0;
	}
}

.caret-top {
	transform: rotate(180deg);
}

// Breadcrumbs
.breadcrumb {
	align-items: center;
	color: $label-color-dark-gray-2;
}
.breadcrumb-item + .breadcrumb-item {
	padding-left: 0.8rem;
	&::before {
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");
		padding-right: 0.8rem;
	}
}
.breadcrumb-item.active {
	font-size: 1.5rem;
	font-weight: 700;
	color: $bg-dark-blue;
}

// Tabs
.nav.nav-tabs-custom {
	gap: 0.8rem;
	flex-wrap: nowrap;
	max-width: 100%;
	overflow: auto;
	@include hide-scrollbar;
	.nav-custom.nav-link {
		border-radius: 0.8rem 0.8rem 0 0;
		border: 1px solid $active;
		padding: 1.6rem;
		font-size: 1.5rem;
		line-height: 1.8rem;
		font-weight: 700;
		color: $blue-primary;
		min-width: 13rem;
		width: max-content;
		text-align: center;
		position: relative;
		border-bottom: 0;

		&.active {
			background-color: white;
			border-color: $bg-second-light;
			&:after {
				content: "";
				position: absolute;
				background-color: white;
				z-index: 1;
				width: 100%;
				bottom: -2px;
				left: 0;
				height: 4px;
			}
		}
	}
}
.tab-content-custom.tab-content {
	padding: 4rem;
	background-color: white;
	border-radius: 0 0.8rem 0.8rem 0.8rem;
	border: 1px solid $bg-second-light;
}

//tooltip
.custom-tooltip {
	position: relative;
	// display: flex;

	// Bootstrap modal being a bruh momentum
	z-index: 1100;

	&.show {
		opacity: 1;
	}

	.tooltip-inner {
		height: max-content;
		width: max-content;
		max-width: 25rem;

		background-color: $label-color-dark-gray;
		color: white;

		font-weight: 500;
		font-size: 0.85rem;

		border-radius: 0.5rem;
		padding: 0.5rem 1rem;
		text-align: left;
	}

	&.long .tooltip-inner {
		max-width: 50rem;
	}

	&.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before,
	&.bs-tooltip-bottom .tooltip-arrow::before {
		border-bottom-color: $label-color-dark-gray;
	}
	&.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before,
	&.bs-tooltip-top .tooltip-arrow::before {
		border-top-color: $label-color-dark-gray;
	}
	&.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before,
	&.bs-tooltip-start .tooltip-arrow::before {
		border-left-color: $label-color-dark-gray;
	}
	&.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before,
	&.bs-tooltip-end .tooltip-arrow::before {
		border-right-color: $label-color-dark-gray;
	}

	span {
		display: block;
	}
}

// Responsiveness
.show-desktop,
.show-tablet,
.show-mobile {
	display: none !important;
}

.show-desktop {
	@media only screen and (min-width: 769px) {
		display: flex !important;
	}
}
.show-tablet {
	@media only screen and (min-width: $sm) and (max-width: $md) {
		display: flex !important;
	}
}
.show-mobile {
	@media only screen and (max-width: $sm) {
		display: flex !important;
	}
}
.w-mobile-100 {
	@media only screen and (max-width: 960px) {
		width: 100% !important;
	}
}
.w-desktop-100 {
	@media only screen and (min-width: 960px) {
		width: 100% !important;
	}
}
.d-flex-desktop {
	display: flex;
	@media only screen and (max-width: 960px) {
		flex-direction: column;
		gap: 1rem;
	}
}

.dropdown-menu {
	--bs-dropdown-border-color: #ccdaef;
	--bs-dropdown-border-radius: 0.8rem;
	--bs-dropdown-header-padding-x: 0;
	--bs-dropdown-header-padding-y: 0;
	box-shadow: 0px 7px 13px 0px #0000001c;
}

.dropdown-item {
	border-width: 1px;
	border-style: solid;
	border-color: white;
	--bs-dropdown-item-padding-x: 1.6rem;
	--bs-dropdown-item-padding-y: 1rem;
	--bs-dropdown-link-hover-bg: #f2f7ff;
	--bs-dropdown-link-active-bg: #f2f7ff;
	cursor: pointer;

	&:active {
		border-color: $text-black;
	}
}

// WYSIWYG Force styling
.force-styling-wysiwyg {
	& * {
		font-family: "Montserrat", sans-serif !important;
		line-height: 1.75;
	}
	& *:not(strong):not(b) {
		font-weight: 500;
	}
	& *:not(h1):not(h2):not(h3):not(h4):not(h5) {
		font-size: 1.6rem !important;
	}
	h1 {
		font-size: 4.5rem !important;
	}
	h2 {
		font-size: 3.5rem !important;
	}
	h3 {
		font-size: 2.5rem !important;
	}
	h4 {
		font-size: 2.1rem !important;
	}
	h5 {
		font-size: 1.6rem !important;
	}
	p,
	ul,
	ol,
	address,
	pre {
		margin-bottom: 1.75rem;
	}
	table {
		margin-left: auto;
		margin-right: auto;
	}
}
.force-font-wysiwyg {
	& * {
		font-family: "Montserrat", sans-serif !important;
		line-height: 1.75;
	}
	& *:not(strong):not(b) {
		font-weight: 500;
	}
	p,
	ul,
	ol,
	address,
	pre {
		margin-bottom: 1.75rem;
	}
	table {
		margin-left: auto;
		margin-right: auto;
	}
}

.positionRelative {
	position: relative;
}

// Carousel styling
.carousel-cont {
	position: relative;
	color: $text-black;

	.slick-track {
		margin-left: 0;
		margin-right: 0;
	}

	.carousel-card {
		padding: 0 0.8rem;
		max-width: 100%;
	}

	.nav-button {
		@include flex-all-center;
		width: 3.2rem;
		height: 3.2rem;
		border-radius: 50%;
		box-shadow: 0px 7px 13px rgba(black, 0.11);
		background-color: white;
		border: 2.63px solid $btn-primary-default;
		position: absolute;
		top: 50%;
		z-index: 1;
		transition: ease 250ms;

		&.prev {
			left: 0;
			transform: translate(-50%, -50%);
		}

		&.next {
			right: 0;
			transform: translate(50%, -50%);
		}

		&.prev svg {
			transform: rotate(90deg);
		}

		&.next svg {
			transform: rotate(-90deg);
		}

		&:disabled {
			background-color: $bg-container;
		}
	}

	@media (hover: hover) {
		.nav-button {
			visibility: hidden;
			opacity: 0;

			&.prev {
				left: 0;
				transform: translate(0%, -50%);
			}

			&.next {
				right: 0;
				transform: translate(-0%, -50%);
			}
		}

		&:hover {
			.nav-button {
				visibility: visible;
				opacity: 1;
				&.prev {
					transform: translate(-50%, -50%);
				}

				&.next {
					transform: translate(50%, -50%);
				}
			}
		}
	}
}

// --------- Dynamic class generation starts here --------- //

/*
	Sizes:
	sm: 1.2rem, md: 2.4rem, lg: 3.2rem
	you can use something like .me-sm to put margin-right 1.2rem
*/
$sizes: (
	"xxs": 0.4rem,
	"xs": 0.8rem,
	"sm": 1.2rem,
	"std": 1.6rem,
	"med": 1.8rem,
	"md": 2.4rem,
	"lg": 3.2rem,
	"xl": 4.2rem,
);

// Automatically set margin and padding based on sizes
@each $name, $size in $sizes {
	.me-#{$name} {
		margin-right: #{$size} !important;
	}
	.ms-#{$name} {
		margin-left: #{$size} !important;
	}
	.mt-#{$name} {
		margin-top: #{$size} !important;
	}
	.mb-#{$name} {
		margin-bottom: #{$size} !important;
	}
	.mx-#{$name} {
		margin-left: #{$size} !important;
		margin-right: #{$size} !important;
	}
	.my-#{$name} {
		margin-top: #{$size} !important;
		margin-bottom: #{$size} !important;
	}
	.m-#{$name} {
		margin: #{$size} !important;
	}
	.pe-#{$name} {
		padding-right: #{$size} !important;
	}
	.ps-#{$name} {
		padding-left: #{$size} !important;
	}
	.pt-#{$name} {
		padding-top: #{$size} !important;
	}
	.pb-#{$name} {
		padding-bottom: #{$size} !important;
	}
	.px-#{$name} {
		padding-left: #{$size} !important;
		padding-right: #{$size} !important;
	}
	.py-#{$name} {
		padding-top: #{$size} !important;
		padding-bottom: #{$size} !important;
	}
	.p-#{$name} {
		padding: #{$size} !important;
	}
	.gap-#{$name} {
		gap: #{$size} !important;
	}
}

// --------- Dynamic class generation ends here --------- //
a {
	color: $text-black;
	text-decoration: none;

	&:hover,
	&:visited,
	&:active {
		color: unset;
	}
}
