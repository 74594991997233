@import "./../../../colorvariable.scss";

.custom-navigation {
	&.flexBox {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 1em;
		padding-bottom: 1em;
		flex-wrap: wrap;
		& .text {
			font-weight: 700;
			margin-bottom: 1rem;
		}
		& .page-num {
			text-align: center;
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			-moz-appearance: textfield; /* Firefox */
		}
		& .pagination {
			margin-bottom: 1rem;
			border: 1px solid #D0D5DD;
			border-radius: .8rem;
		}
		& .page-item {
			& .page-link {
				color: black;
				background-color: white;
				cursor: pointer;
				font-size: 1.4rem;
				padding: 1rem 1.6rem;
				font-weight: 500;
				transition: 100ms;

				&:hover {
					background: $bg-input-disable;
				}
			}

			&.active {
				& .page-link {
					color: $color-pagination-arrow;
					background: $bg-input-disable;
					border-color: var(--bs-pagination-hover-border-color);
					cursor: auto;
				}
			}
		}
		& .page-item:first-of-type {
			border-radius: 0.8rem 0 0 0.8rem;
			overflow: hidden;
			& .page-link {
				border-radius: 0.8rem 0 0 0.8rem;
				background: var(--themeBlue);
				color: $color-pagination-arrow;
				border-color: var(--themeBlue);
			}
			&.disabled .page-link {
				color: #919191;
				background: $bg-input-disable;
				border-color: #dddddd;
			}
		}
		& .page-item:last-of-type {
			border-radius: 0 0.8rem 0.8rem 0;
			overflow: hidden;
			& .page-link {
				border-radius: 0 0.8rem 0.8rem 0;
				color: $color-pagination-arrow;
			}
			&.disabled .page-link {
				color: #919191;
				background: $bg-input-disable;
				border-color: #dddddd;
			}
		}
	}
}
