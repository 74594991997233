@import "../../colorvariable.scss";
@import "../../mixin.scss";

.apply-job {
	.modal-body {
		max-height: 100%;
		padding: 0;
		overflow: hidden;
	}
}

.aj-form {
	display: flex;
	flex-direction: column;

	.progress-bar {
		padding: 3.2rem;
		padding-bottom: 0;
		text-align: left;

		h4 {
			margin-top: 2rem;
			font-weight: 700;
		}
	}
}

.btn-grp {
	background: #f9fafb;
	border: 2px solid $btn-text-gray-disable;
	padding: 2.4rem 3.2rem;
	border-bottom-right-radius: 1.8rem;
	border-bottom-left-radius: 1.8rem;
}

.form-container {
	flex: 1;
	position: relative;
	height: 100%;

	.btn-grp {
		position: sticky;
		width: 100%;
		bottom: 0;
	}
}

.contact-form {
	margin-block: 3rem;
	display: flex;
	flex-direction: column;
	gap: 3rem;

	.header {
		padding-inline: 3.2rem;
		display: flex;
		align-items: center;
		gap: 3rem;

		.avatar {
			height: 10rem;
			width: 10rem;
			border-radius: 50%;
			object-fit: cover;
			object-position: top;
		}

		.userDetails {
			p {
				margin-bottom: 0;
			}

			.name {
				font-weight: 600;
				@include text-lg;
			}

			.email {
				color: #98a2b3;
				font-weight: 400;
				letter-spacing: 0.7px;
				@include text-xs;
			}

			.company-role {
				@include text-sm;
				font-weight: 400;
			}
		}
	}

	.body {
		max-height: calc(80vh - 20rem);
		padding-inline: 3.2rem;

		overflow: auto;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.footer {
		padding-inline: 3.2rem;

		p {
			@include text-sm;
		}
	}
}

// Resume
.resume {
	.file-card {
		width: 100%;
		border: 1px solid $border-input;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: 8px;
		overflow: hidden;
		position: relative;

		.file-name {
			display: flex;
			align-items: center;
			padding-inline: 1rem;
			overflow: hidden;

			img {
				height: 4rem;
				margin-right: 1rem;
			}

			p {
				@include truncate(2);
				margin-bottom: 0;
				font-weight: 500;
				font-size: 1.5rem;
			}
		}

		.file-card-btns {
			background: #e9ebf0;
			display: flex;
			align-items: center;

			.btn-view {
				height: 6rem;
				transition: 0.1s all ease-in;

				@include flex-all-center;

				& .J-btnComp-textGray {
					padding-inline: 1rem;
				}
			}
		}

		.loader-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			& .loader-container {
				background: #f9fafb;
				text-align: center;
				opacity: 0.5;

				img {
					height: 6rem;
				}
			}
		}
	}
}

.review {
	section {
		padding-top: 3rem;

		border-top: 2px solid $btn-text-gray-disable;

		.section-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 3rem;

			h4 {
				margin-bottom: 0;
				font-weight: 600;
				font-size: 1.7rem;
				color: $text-black;
			}

			.btn-edit .J-btnComp-textGray {
				padding: 0;

				span {
					color: $text-black;
					font-weight: 600;
					font-size: 1.5rem;
				}
			}
		}

		& .section-info {
			margin-block: 1rem;

			.question {
				.answer {
					color: $text-black;
					@include text-md;
				}
			}

			.light-text {
				color: $btn-text-gray-default;
				font-size: 1.6rem;
			}
		}

		& .contact {
			.user {
				display: flex;
				align-items: center;
				gap: 3rem;
				margin-bottom: 3rem;

				img {
					height: 10rem;
					width: 10rem;
					border-radius: 50%;

					object-fit: cover;
					object-position: top;
				}

				&-details {
					display: flex;
					flex-direction: column;

					p {
						margin-bottom: 0;
					}

					.name {
						font-weight: 600;
						@include text-lg;
					}

					.email {
						color: #98a2b3;
						font-weight: 400;
						letter-spacing: 0.7px;
						@include text-sm;
					}

					.company-role {
						@include text-md;
						font-weight: 400;
					}
				}
			}

			.user-contact {
				p {
					margin-bottom: 0;
				}

				.label {
					font-weight: 400;
					color: $btn-text-gray-default;
				}
				.contact {
					color: $text-black;
					@include text-lg;
				}
			}
		}
	}
}

.question {
	margin-bottom: 1rem;

	p {
		font-size: 1.4rem;
		color: $btn-text-gray-default;
	}

	.isRequired::after {
		content: "*";
		color: $text-danger;
	}
	// .form-check {
	// 	padding-left: 0;
	// 	display: flex;
	// 	align-items: center;
	// 	gap: 1rem;
	// 	margin-bottom: 1rem;

	// 	.form-input-check {
	// 		accent-color: $text-black;
	// 	}
	// }
}
