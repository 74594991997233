@import "./../../colorvariable.scss";
@import "../../mixin.scss";
@import "../../variables.scss";

.hcm {
	&.hcm-container {
		display: flex;
		flex-direction: column;
		position: fixed;
		height: 100%;
		left: 0;
		right: 0;
		top: -150vh;
		z-index: 99;
		background-color: $white-color;
		transition: top 0.3s;
		overflow: auto;

		&.visible {
			top: 0;
		}
	}

	.container.hn {
		height: min-content;
		padding: 0 2.75rem;
	}

	.main-menu {
		margin-top: 32px;
		flex: 1;
		overflow: auto;

		.login-signup-container {
			padding: 1.6rem;
			display: flex;
			gap: 1.2rem;
		}

		.hn-profile-label,
		.hn-dropdown > .hn-dropdown-item,
		.jobs-company-menu > .menu-item {
			@include text-xl;
			font-weight: 600;
			color: $btn-text-gray-default;
			line-height: 140%;
		}

		.jobs-company-menu {
			margin: 2rem;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			> .menu-item {
				margin: 2rem 0;
			}
		}

		.dropdown {
			.dropdown-item {
				display: flex;
				gap: 1rem;
				align-items: center;

				svg {
					height: 2rem;
					width: 2rem;
					color: $text-black;
					visibility: hidden;
				}

				&.active {
					svg {
						height: 2rem;
						width: 2rem;
						color: $text-black;
						visibility: visible;
					}

					.caption {
						color: $text-black;
					}
				}
			}
		}
	}
}

.logout-btn {
	margin: 2rem;
}

// Dropdown component
.dropdown-comp {
	> :nth-child(1) {
		padding: 1.5rem 2rem;
		gap: 1rem;

		.dropdown-title ~ svg {
			width: 2.5rem;
			height: 2.5rem;
			transition: transform 0.2s;

			path {
				stroke: $label-color-blue;
			}
		}
	}

	.dropdown-title,
	.dropdown-item-container > .dropdown-item {
		@include text-xl;
		font-weight: 600;
		color: $btn-text-gray-default;
		line-height: 140%;
	}

	.left-icon {
		width: 4rem;
		height: 4rem;
		border-radius: 50rem;
		object-fit: fill;
		overflow: hidden;

		> * {
			width: 100%;
			height: 100%;
			object-fit: fill;
		}
	}

	.dropdown-item-container {
		gap: 1rem;
		display: flex;
		flex-direction: column;
		box-shadow: none;
		visibility: collapse;
		max-height: 0;
		transition: all 1s;
		overflow: hidden;
		padding: 0;

		> .dropdown-item {
			padding: 1.5rem 7rem;
		}
	}

	&.active {
		> :nth-child(1) .dropdown-title ~ svg {
			transform: rotate(180deg);
		}

		.dropdown-item-container {
			visibility: visible;
			max-height: 50rem;
			overflow: auto;
		}
	}
}
